import React, { useState, useCallback, createContext, ReactNode, useContext } from 'react';
import { ToastColor } from 'ursa-react/dist/components/Toast/Toast';

interface NotificationObject {
  message: string;
  status: number;
  color: ToastColor
}

interface NotificationContextProps {
  notification: NotificationObject | null;
  addNotification: (notification: NotificationObject) => void;
  removeNotification: () => void;
}

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = createContext<NotificationContextProps>({
  notification: null,
  addNotification: () => { },
  removeNotification: () => { }
});

function NotificationProvider({ children }: NotificationProviderProps) {
  const [notification, setNotification] = useState<NotificationObject | null>(null);

  const removeNotification = () => setNotification(null);
  const addNotification = (notificationObject: NotificationObject) => setNotification(notificationObject);

  const contextValue: NotificationContextProps = {
    notification,
    addNotification: useCallback((notificationObject) => addNotification(notificationObject), []),
    removeNotification: useCallback(() => removeNotification(), [])
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}


function useNotification() {
  const { notification, addNotification, removeNotification } = useContext(NotificationContext);
  return { notification, addNotification, removeNotification };
}

export { useNotification, NotificationProvider };
import {
  AuthApi,
  Configuration,
  ContainerApi,
  FacilityApi,
  InventoryApi,
  OrganizationApi,
  PlaceApi,
  ProductApi,
  UserApi,
  ZoneApi,
} from 'viso-typescript-client';
import {ConfigurationParameters} from 'viso-typescript-client'

const VisoClient = () => {
  const configParam: ConfigurationParameters = {
    apiKey: '',
    basePath: '',
    accessToken: '',
  }
  const config = new Configuration(configParam);
  const userData = JSON.parse(localStorage.getItem('user') as string);
  if (userData != null) {
    config.accessToken = userData.access_token;
  }
  const client = {
    auth: new AuthApi(config),
    container: new ContainerApi(config),
    facility: new FacilityApi(config),
    inventory: new InventoryApi(config),
    org: new OrganizationApi(config),
    place: new PlaceApi(config),
    product: new ProductApi(config),
    user: new UserApi(config),
    zone: new ZoneApi(config),
  };
  return client;
};

export {VisoClient};

/* eslint-disable require-jsdoc */
import React, {useEffect, useContext} from 'react';
import {redirect, useNavigate} from 'react-router-dom';
import {deleteFromStorage} from '@rehooks/local-storage';
import {AuthContext} from '../context/AuthContext';

const Logout = () => {
  const {setUser} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    setUser(null);
  }
  useEffect(() => {
    deleteFromStorage('user');
    redirect('/');
    handleLogout();
    navigate('/');
  }, []);

  return (
    <div className='card'>
      <h1>Logout</h1>
    </div>
  );
}

export default Logout;

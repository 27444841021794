import React, {useState, useEffect} from 'react';
import {Button, Fieldset, Input, Label} from 'ursa-react';
import {VisoClient} from '../utils/visoClient';
import { useNotification } from '../context/NotificationProvider';

const Organization = () => {
  const [orgName, setOrgName] = useState('');
  const [loading, setLoading] = useState(true);
  const [orgObject, setOrgObject] = useState(null);
  const { addNotification } = useNotification();

  const handleCreateOrg = async () => {
    try {
      const res = await VisoClient().org.createOrganization(orgName);
      setOrgObject(res.data);
      addNotification({ message: `Created org: ${res.data.name}`, color: 'primary' })
    } catch (error) {
      // console.log(JSON.stringify(error));
      addNotification({ message: `Unable to create org: ${res.data.name}`, color: 'secondary' })
    }
  };

  const handleGetOrg = async () => {
    const resul = await VisoClient().org.getActiveOrganization();
    console.log(resul.data);
    setOrgObject(resul.data);
    setLoading(false);
  };

  useEffect(() => {
    handleGetOrg();
  }, []);

  return (
    <>
      { loading ? (
      <>
      </>
    ): (
      orgObject ? (
        <div>
          <h4>You have a Org of: {orgObject.name} </h4>
          <p>Move on to make your facilites</p>
        </div>
      ) : (
        <>
          <h4>Setup an Organization</h4>
          <Fieldset>
            <Label>Organization Name</Label>
            <Input
              type={'text'}
              name={'org_name'}
              onChange={(e) => setOrgName(e.target.value)}
              value={orgName}
            />
            <Button
              onClick={handleCreateOrg}
              disabled={false}>Create Org</Button>
          </Fieldset>
        </>
      )
    )}
    </>
  );
};

export default Organization;



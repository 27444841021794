import React from 'react';
import Avatar from 'boring-avatars';
import {Link} from 'react-router-dom';

const AppBar = () => {
  return (
    <div className="app-bar">
      <div className='account'>
        <Link to='/account'>
          <Avatar
            size={50}
            name={JSON.parse(localStorage.getItem('user')).email}
            variant="marbel"
            square={true}
            colors={['#547980', '#0066a1', '#9DE0AD', '#E5FCC2']}
          />
        </Link>
      </div>
      <div className='right-menu'>
      </div>
    </div>
  );
};

AppBar.propTypes = {
};

export default AppBar;

import React, { useState } from 'react';
import { Button, Fieldset, Input, Label, Typography, Card, Grid, Row, Column } from 'ursa-react';
import { Link } from 'react-router-dom';
import { VisoClient } from '../utils/visoClient';

// eslint-disable-next-line require-jsdoc
function Signup() {
  const [loading, setLoading] = useState(false);
  const [createdSuccess, setCreatedSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  const handleSignup = async () => {
    try {
      setLoading(true);
      const signUpData = {
        email: email,
        first: firstName,
        last: lastName,
        phone: phone,
        password: password,
      };
      const response = await VisoClient().auth.signUpApiV1AuthSignUpPost(
        signUpData,
      );
      console.log(response);
      setLoading(false);
      setCreatedSuccess(true);
    } catch (error) {
      setLoading(false);
      setCreatedSuccess(false);
      console.log('Error thrown:', error.message);
    }
  };
  return (
      <Card>
        <Grid>
          <Row>
            <Column>
              <Typography content='Sign Up' type='h1' />
            </Column>
          </Row>
          <Row>
            {createdSuccess ? (
              <Column>
                <br />
                <br />
                <Typography type='h4'>Great news we made your account!</Typography>
                <br />
                <p>A validation email has been sent to your email provided.
                  Please click the confirm link to login to your account.
                </p>
                <br />
                <Typography type='p'>
                  Return to Login? <Link to="/login">Log In</Link>
                </Typography>
              </Column>
            ) : (
              loading ? (
                <>
                  <Typography type='h4'>Creating an account just for you!</Typography>
                </>
              ) : (
                <form onSubmit={handleSignup}>
                  <Fieldset>
                    <Label>First Name</Label>
                    <Input onChange={(e) => setFirstName(e.target.value)}>
                    </Input>
                    <Label>Last Name</Label>
                    <Input onChange={(e) => setLastName(e.target.value)}>
                    </Input>
                    <Label>Phone</Label>
                    <Input
                      name='phone'
                      type={'tel'}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)} />
                    <Label>Email</Label>
                    <Input
                      name='email'
                      type={'email'}
                      onChange={(e) => setEmail(e.target.value)}></Input>
                    <Label>Password</Label>
                    <Input
                      type={'password'}
                      onChange={(e) => setPassword(e.target.value)} />

                    <br />
                    <Button
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault(); handleSignup();
                      }}>
                      Signup
                    </Button>
                    <br />
                    <p>Already have an account?</p> <Link to="/login">Log In</Link>
                  </Fieldset>
                </form>
              )
            )}
          </Row>
        </Grid>
      </Card>
  );
}

export default Signup;

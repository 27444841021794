import React, { createContext } from 'react';

interface AuthContextTypes {
  user: {access_token?: ''} | null;
  setUser: (user: object) => void;
}

export const AuthContext = createContext<AuthContextTypes>({
  user: null,
  setUser: () => {}
});

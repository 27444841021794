import React, {useState, useEffect} from 'react';
import {Button, Fieldset, Input, Label, List} from 'ursa-react';
import {VisoClient} from '../utils/visoClient';

const Facility = () => {
  const [facilityName, setFacilityName] = useState('');
  const [facilityList, setFacilityList] = useState([]);

  const handleCreateFacility = async () => {
    try {
      const res = await VisoClient().facility.createFacility(facilityName);
      console.log(res);
      handleRefreshFacilities();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshFacilities = async () => {
    const resul = await VisoClient().facility.getFacilities();
    const list = []
    for(let zone of resul.data.results){
      list.push(zone.name)
    }
    setFacilityList(list);
  };

  useEffect(() => {
    handleRefreshFacilities();
  }, []);

  return (
    <div className='row'>
      <div className='flex-half'>
        <h4>Setup Facilities</h4>
        <Fieldset>
          <Label>Facility Name</Label>
          <Input
            type={'text'}
            name={'facility_name'}
            onChange={(e) => setFacilityName(e.target.value)}
            value={facilityName}></Input>
          <Button
            onClick={handleCreateFacility}
            disabled={false}>Create a Facility</Button>
        </Fieldset>
      </div>
      <div className='flex-half'>
        <h4>Your Current Facilities:</h4>
        <List items={facilityList} />
      </div>
    </div>
  );
};


export default Facility;



import React, { useState } from 'react';
import { Nav } from 'rsuite';
import { Icon } from '@rsuite/icons';

import { Link } from 'react-router-dom';
import { BiLogOut, BiBuilding, BiHome } from 'react-icons/bi';
import { GiCardPickup, GiBoxUnpacking } from 'react-icons/gi';

function NavLinks() {
    const [activeKey, setActiveKey] = useState('1');

    return (
        <Nav className='nav-items' activeKey={activeKey} onSelect={setActiveKey}>
            <Nav.Item
                icon={<Icon as={BiLogOut} />}
                as={Link}
                to="/logout">
                Logout
            </Nav.Item>
            <Nav.Item
                icon={<Icon as={BiHome} />}
                as={Link} to="/">
                Dashboard
            </Nav.Item>
            <Nav.Item
                icon={<Icon as={BiBuilding} />}
                as={Link} to={`/inventory`}>
                Inventory
            </Nav.Item>
            <Nav.Item
                icon={<Icon as={GiCardPickup} />}
                as={Link} to={`/picking`}>
                Picking
            </Nav.Item>
            <Nav.Item
                icon={<Icon as={GiBoxUnpacking} />}
                as={Link} to={`/replenishment`}>
                Replenishment
            </Nav.Item>
            <Nav.Item
                icon={<Icon as={GiCardPickup} />}
                as={Link} to={`/cycle-count`}>
                Cycle Count
            </Nav.Item>
        </Nav>
    );
}

export default NavLinks;
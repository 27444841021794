import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from 'ursa-react/dist/components';

function Footer() {
    return (
        <>
            <footer>
                <Typography content="&copy; 2023 Viso Management" type='p' />
            </footer>
        </>
    );
}

export default Footer;
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';

import { AuthContext } from './context/AuthContext';
import LogoBar from './components/LogoBar';
import MainNav from './components/MainNav';
import AppBar from './components/AppBar';
import LeftNav from './components/LeftNav';

import Home from './pages/Home';
import Login from './pages/Login';
import Account from './pages/Account';
import Inventory from './pages/Inventory';
import Signup from './pages/Signup';
import Error404 from './pages/404';
import Logout from './pages/Logout';
import Picking from './pages/Picking';
import Footer from './components/Footer';
import Replenishment from './pages/Replenishment';
import Product from './components/Product';
import ProductView from './pages/Product';
import ManageOrg from './pages/ManageOrg';
import NavLinks from './components/NavLinks';
import MobileNav from './components/MobileNav';
import { NotificationProvider } from './context/NotificationProvider';
import { Notification } from './components/Notification';
import CycleCount from './pages/CycleCount';


const App = () => {
  const [user, setUser] = useState<null | object>(null);

  useEffect(() => {
    let userData = localStorage.getItem('user') as string;
    setUser(JSON.parse(userData));
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <NotificationProvider>
        {user === null ? (
          <div>
            <Notification/>
            <LogoBar />
            <div className='content-container'>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
            </div>
            <Footer />
          </div>
        ) : (
          <>
          <Notification/>
            <AppBar />
            <div className="main-container">
              <LeftNav />
              <div className="main-section">

                <MobileNav />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/manage-org" element={<ManageOrg />} />
                  <Route path="/account" element={<Account />} />
                  <Route path='/picking' element={<Picking />} />
                  <Route path='/cycle-count' element={<CycleCount />} />
                  <Route path="/inventory/" element={<Inventory />} />
                  <Route path="/inventory/" element={<Inventory />} />
                  <Route path="/inventory/product/:product_id" element={<ProductView />} />
                  <Route path="/replenishment/" element={<Replenishment />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </div>
            </div>
          </>
        )}
      </NotificationProvider>
    </AuthContext.Provider>
  );
};

export default App;

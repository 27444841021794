import {
  Drawer,
  Pagination,
  Table,
  Input,
  TagPicker,
  Nav,
} from 'rsuite';
import { useNavigate } from "react-router-dom";
import { VisoClient } from '../utils/visoClient';
import React, { useState, useEffect } from 'react';
import { Button } from 'ursa-react';
import { Card, Typography } from 'ursa-react/dist/components';

const Inventory = () => {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [limitValue, setLimitValue] = React.useState(30);
  const [pageState, setPageState] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [tableData, setTableData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [facilityList, setFacilityList] = useState([]);
  const [filterFacilityList, seFilterFacilityList] = useState([]);

  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    const { product_sku } = rowData;
    navigate(`/inventory/product/${product_sku}`);
  };

  const handleSetFilters = () => {
    setFiltersOpen(false);
  }

  const handleLimitChange = (newLimit) => {
    setLimitValue(newLimit);
    setPageState(null);
  };

  const handlePageNumber = (newPage) => {
    console.log(newPage);
    setPageNumber(newPage);
  }

  const handleGetInventory = async () => {
    if (pageState != null) {
      const response = await VisoClient().inventory.getInventories(undefined, undefined, limitValue, pageState);
      console.log(response.data);
      setTableData(response.data.results);
      setPageState(response.data.paging_state);
      console.log(response.data.paging_state);
      setLoading(false);
    } else {
      const response = await VisoClient().inventory.getInventories(undefined, undefined,limitValue);
      console.log(response.data);
      console.log(response.data.paging_state);

      setTableData(response.data.results);
      setPageState(response.data.paging_state);
      setLoading(false);
    }

  };

  const handleRefreshFacilities = async () => {
    const res = await VisoClient().facility.getFacilities();
    const arrayFacility = res.data.results;
    console.log(arrayFacility);

    let filterFacility = [];
    for(let facility of arrayFacility){
      filterFacility.push({ label: facility.name, value: facility.id });
    }

    const itemsRes = arrayFacility.reduce((acc, curr) => {
      acc[curr.id] = curr.name;
      return acc;
    }, {});

    setFacilityList(itemsRes);
    seFilterFacilityList(filterFacility);
  };

  useEffect(() => {
    handleGetInventory();
    handleRefreshFacilities();
  }, [limitValue, pageNumber]);

  return (
    <Card>
      <div className='column'>
        <div className='row justify-space-between'>
          <Typography content='Inventory' type='h1' />
          <Button
            onClick={() => setFiltersOpen(true)}>
            Filters
          </Button>
        </div>
        <div className=''>
          <Drawer
            size={'xs'}
            placement={'right'}
            open={filtersOpen}
            onClose={() => setFiltersOpen(false)}>
            <Drawer.Header>
              <h3>Filters</h3>
              <Drawer.Actions>
                <button
                  onClick={() => setFiltersOpen(false)}>
                  Cancel
                </button>
                <button
                  onClick={() => handleSetFilters(false)}>
                  Confirm
                </button>
              </Drawer.Actions>
            </Drawer.Header>
            <Drawer.Body>
              <label>Product Name:</label>
              <Input size={'sm'} />
              <label>Facilities: </label>
              <TagPicker size={'sm'} data={filterFacilityList} style={{ width: 600 }} onSelect={(e) => {console.log(e)}} />
            </Drawer.Body>
          </Drawer>
          <Nav appearance="subtle">
            <Nav.Item active={true}>All</Nav.Item>
          </Nav>
          <Table
            height={400}
            loading={loading}
            rowKey="inventory_id"
            data={tableData}
            onRowClick={handleRowClick}>

            <Table.Column flexGrow={1} minWidth={150} fixed={true}>
              <Table.HeaderCell>Sku</Table.HeaderCell>
              <Table.Cell dataKey="product_sku" />
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={100}>
              <Table.HeaderCell>Product Name</Table.HeaderCell>
              <Table.Cell dataKey="product_name" />
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={100}>
              <Table.HeaderCell>Qty On Hand</Table.HeaderCell>
              <Table.Cell dataKey="qty_on_hand" />
            </Table.Column>

            <Table.Column flexGrow={1} minWidth={80}>
              <Table.HeaderCell>Facility</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <>
                    {facilityList[rowData.facility_id]}
                  </>
                )}
              </Table.Cell>
            </Table.Column>

          </Table>
          <Pagination
            layout={['-', 'limit', '|', 'pager']}
            size={'md'}
            prev={true}
            next={true}
            first={false}
            last={false}
            ellipsis={true}
            boundaryLinks={true}
            limit={limitValue}
            limitOptions={[5, 6, 7, 8, 30, 50, 100]}
            onChangeLimit={(limit) => { handleLimitChange(limit) }}
            activePage={pageNumber}
            onChangePage={(pageNum) => { handlePageNumber(pageNum) }}
          />
        </div>
      </div>
    </Card>
  );
};

export default Inventory;

import React from 'react';
import { Typography, Link } from 'ursa-react/dist/components';

const VisoLogo = () => {
  const link = <Typography type='h1' content="Viso Management" />
  return (
    <Link type='a' href='/' content={link}></Link>
  );
};

export default VisoLogo;

/* eslint-disable require-jsdoc */
import useLocalStorage from '@rehooks/local-storage';
import React, { useEffect, useState } from 'react';
import { Pagination, SelectPicker, Table, TagPicker, Toggle } from 'rsuite';
import { Button, Fieldset, Label, Input, Chip, Typography, Card } from 'ursa-react';
import CopyIcon from '@rsuite/icons/Copy';
import AccountNav from '../components/AccountNav';
import { Modal } from 'ursa-react';
import { ChipVariant, HTMLColor } from 'ursa-react/dist/components/Chip/Chip';
import { VisoClient } from '../utils/visoClient';
import { OrganizationUser } from 'viso-typescript-client';
import { HeadingType } from 'ursa-react/dist/components/Typography/Typography';

const ManageOrg = () => {
  const user = useLocalStorage('user') as any;
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({}) as any;
  const [open, setOpen] = React.useState(false);
  const [usersList, setUsersList] = useState([]) as any;
  const [urlRef, setUrlRef] = useState('');
  const handleClose = () => setOpen(false);

  const handleRowClick = (rowData: any) => {
    const { uuid } = rowData;
    setSelectedUser(rowData);
    console.log("userid selected is: ", uuid);
    setOpen(true)
  };

  const copyToClipboard = (event: any) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(event.target.id);
    } else {
      return document.execCommand('copy', true, event.target.id);
    }
  };

  const loadOrgData = async () => {
    const response = await VisoClient().org.getOrganizationUsers();
    const res = response.data.results as OrganizationUser[]
    setUsersList(res)
    console.log(res);
  };

  useEffect(() => {
    console.log(user)
    setUrlRef(`https://www.visomanagement.com/invite-sign-up?token=${user[0].user.active_organization_id}`)
    loadOrgData()
  }, []);

  return (
    <Card>
      <div className='column'>
        <AccountNav />

        <div className='row'>
          <Typography type={HeadingType.H3} content='Manage Organization' />
        </div>
        <div className=''>
          <div className=''>
            <Table
              height={300}
              loading={loading}
              rowKey="user_id"
              data={usersList}
              onRowClick={handleRowClick}>

              <Table.Column flexGrow={1} minWidth={80} fixed={true}>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.Cell dataKey="first" />
              </Table.Column>

              <Table.Column flexGrow={1} minWidth={80}>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.Cell dataKey="last" />
              </Table.Column>

              <Table.Column flexGrow={1} minWidth={180}>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.Cell dataKey="email" />
              </Table.Column>

              <Table.Column flexGrow={1} minWidth={100}>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => (
                    <>
                      {rowData.role == "admin" ? <Chip label={'Admin'} variant={ChipVariant.NO_OUTLINE} fontColor={HTMLColor.WHITE} bgColor={HTMLColor.RED} /> : <Chip label={'Operator'} variant={ChipVariant.NO_OUTLINE} fontColor={HTMLColor.WHITE} bgColor={HTMLColor.BLUE} />}
                    </>
                  )}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} minWidth={80}>
                <Table.HeaderCell>Verified</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => (
                    <>
                      {rowData.organization_verified ? <Chip label='Approved' variant={ChipVariant.NO_OUTLINE} fontColor={HTMLColor.WHITE} bgColor={HTMLColor.GREEN} /> : <Chip label="Pending" variant={ChipVariant.NO_OUTLINE} fontColor={HTMLColor.WHITE} bgColor={HTMLColor.CYAN} />}
                    </>
                  )}
                </Table.Cell>
              </Table.Column>

              {/* <Table.Column flexGrow={1} minWidth={100}>
                <Table.HeaderCell>Facilities Count</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => (
                    <>
                      {rowData.facilities.length}
                    </>
                  )}
                </Table.Cell>
              </Table.Column> */}

            </Table>
            <Pagination
              layout={['-', 'limit', '|', 'pager']}
              size={'md'}
              prev={true}
              next={true}
              first={false}
              last={false}
              ellipsis={true}
              boundaryLinks={true}
              // limit={limitValue}
              limitOptions={[30, 50, 100]} total={0}            // onChangeLimit={(limit) => { handleLimitChange(limit) }}
            // activePage={pageNumber}
            // onChangePage={(pageNum) => { handlePageNumber(pageNum) }}
            />
          </div>

          <div className='column'>
            <p>Share your invite link to add users to your organization</p>
            <Fieldset>
              <Input title='Share Url' value={urlRef} disabled={true}></Input>
              <br />
              <Button id={urlRef} onClick={copyToClipboard}>
                <CopyIcon />
              </Button>
            </Fieldset>
          </div>

        </div>

        <Modal visible={open} onClose={handleClose} modalTitle='Edit User'>
          <>
            <div>
              <Fieldset>
                <Label>First Name</Label>
                <Input title='First Name' value={selectedUser.first}></Input>
                <Label>Last Name</Label>
                <Input title='First Name' value={selectedUser.last}></Input>
                <Label>Email</Label>
                <Input title="Email" value={selectedUser.email}></Input>
                <Label>Verified</Label>
                <Toggle defaultChecked={selectedUser.organization_verified}></Toggle>
                <Label>Role</Label>
                <SelectPicker defaultValue={selectedUser.role} data={[{ label: 'Admin', value: 'admin' }, { label: 'Operator', value: 'operator' }]}></SelectPicker>
                <Label>Facilities</Label>
                <TagPicker defaultValue={selectedUser.facilities} data={[{ label: 'Testyy', value: 'a' }, { label: 'BigFancy', value: 'b' }]}></TagPicker>
              </Fieldset>
            </div>
            <div>
              <Button onClick={handleClose}>
                Save
              </Button>
              <Button onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </>
        </Modal>

      </div>
    </Card>
  );
};

export default ManageOrg;

import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { VisoClient } from '../utils/visoClient';
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography } from 'ursa-react';
import { Inventory } from 'viso-typescript-client';
import { HeadingType } from 'ursa-react/dist/components/Typography/Typography';

const ProductView = () => {
    const [productData, setProductData] = useState({ sku: "", name: "" });
    const [inventoryInfo, setInventoryInfo] = useState([{ qty_on_hand: null, facility: '' }]);
    const [facilityList, setFacilityList] = useState([]) as any;

    const [totalQty, setTotalQty] = useState(0);
    const { product_id } = useParams();
    const navigate = useNavigate();

    const handleRefreshFacilities = async () => {
        const res = await VisoClient().facility.getFacilities();
        const arrayFacility = res.data.results;

        let filterFacility = [] as any;
        for (let facility of arrayFacility) {
            filterFacility.push({ label: facility.name, value: facility.id });
        }

        const itemsRes = arrayFacility.reduce((acc: any, curr: any) => {
            acc[curr.id] = curr.name;
            return acc;
        }, {});

        setFacilityList(itemsRes);
    };

    const getProductDetails = async (product_id: any) => {
        try {
            const resProduct = await VisoClient().product.getProduct(product_id);
            const resInventory = await VisoClient().inventory.getInventories(product_id, undefined, 100);
            let buildInventory: any = [];
            let buildQty: number = 0;
            let resInv = resInventory.data.results as Inventory[]

            for (let inventory of resInv) {
                buildQty = buildQty + inventory.qty_on_hand;
                buildInventory.push({ qty_on_hand: inventory.qty_on_hand, facility: inventory.facility_id })
            }

            setTotalQty(buildQty);
            setInventoryInfo(buildInventory);
            setProductData(resProduct.data);
        } catch (error) {
            console.log(error);
        }
    };

    const itemDivs = inventoryInfo.map((item, index) => (
        <Card key={index}>
            <div className='column'>
                <p>Facility: {facilityList[item.facility]}</p>
                <p>Quantity On Hand: {item.qty_on_hand}</p>
            </div>
        </Card>
    ));

    const backToSearch = () => {
        navigate(-1);
    }

    useEffect(() => {
        handleRefreshFacilities()
        getProductDetails(product_id);
    }, []);

    return (
        <Card>
            <div className='column'>
                <Typography type={HeadingType.H1} content={productData.name} />
                <h4>SKU: {productData.sku}</h4>

                <h4>Total Quantity On Hand: {totalQty}</h4>
                <h4>Weight: </h4>
                <h4>Dimensions: </h4>

                <br></br>
                <Typography type={HeadingType.H3} content='Facilites' />
                {itemDivs}
                <Button onClick={backToSearch}>Back</Button>
            </div>
        </Card>
    );
};

export default ProductView;

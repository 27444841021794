/* eslint-disable require-jsdoc */
import React, { useState, useContext } from 'react';
import { writeStorage } from '@rehooks/local-storage';
import { Button, Fieldset, Input, Label, Card, Typography, Grid, Row, Column } from 'ursa-react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { VisoClient } from '../utils/visoClient';
import { useNotification } from '../context/NotificationProvider';

function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const handleLogin = async () => {
    try {
      setLoading(true);
      const signIn = {
        email: email,
        password: password,
      };
      const response = await VisoClient().auth.signInApiV1AuthSignInPost(
        signIn,
      );

      setUser(response.data);
      writeStorage('user', response.data);
      console.log(response.data);
      navigate('/');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addNotification({ message: error.message, color: 'secondary' })
      console.log('Error thrown:', error.detail);
    }
  };
  return (
    <Card>
      <Grid>
        <Row>
          <Column>
            <Typography content='Login' type='h1' />
          </Column>
        </Row>
        <Row>
          <Column>
            <form onSubmit={handleLogin}>
              <Fieldset>
                <Label>Email</Label>
                <Input onChange={(e) => setEmail(e.target.value)}></Input>
                <Label>Password</Label>
                <Input
                  type={'password'}
                  onChange={(e) => setPassword(e.target.value)} />
                <br></br>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault(); handleLogin();
                  }}>
                  {loading ? (
                    "Loading"
                  ) : (
                    'Login'
                  )}
                </Button>
                <br></br>
                <p>Don&apos;t have an account? </p><Link to="/signup">Sign Up</Link>
              </Fieldset>
            </form>
          </Column>
        </Row>
      </Grid>
    </Card>
  );
}


export default Login;

import React, { useEffect, useState } from 'react';
import { Button, Card, Fieldset, Input, Label, Typography, ButtonGroup, Row, Column, Grid, Toast } from 'ursa-react';
import { VisoClient } from '../utils/visoClient';
import { HeadingType } from 'ursa-react/dist/components/Typography/Typography';
import { useNotification } from '../context/NotificationProvider';
import { ToastColor } from 'ursa-react/dist/components/Toast/Toast';

const Replenishment = () => {
  const { addNotification } = useNotification();
  const [selectedProduct, setSelectedProduct] = useState({ name: "", sku: "", organization_id: "" })
  const [value, setValue] = useState(1);
  const [placeBarcode, setPlaceBarcode] = useState("")
  const [productBarcode, setProductBarcode] = useState("")

  const handleMinus = () => {
    if (value >= 2) {
      setValue(value - 1);
    }
  };

  const handlePlus = () => {
    setValue(value + 1);
  };

  const handleFindProduct = async (event: any) => {
    // Should find product by the barcode entered
    event.preventDefault();
    try {
      const res = await VisoClient().product.getProduct(productBarcode);
      setSelectedProduct(res.data)
      addNotification({ status: 222, message: "Success Product Found", color: ToastColor.Primary });
    } catch (error) {
      addNotification({ status: 222, message: "There was a problem finding the product", color: ToastColor.Secondary });
    }
  }

  const allowButtonSubmit = () => {
    if (productBarcode !== "") {
      return false;
    }
    return true;
  }

  const handleClearProduct = () => {
    setSelectedProduct({ name: "", sku: "", organization_id: "" })
    setPlaceBarcode("")
    setProductBarcode("")
    setValue(1)
  }

  const handleReplenish = async () => {
    try {
      const res = await VisoClient().product.replenishProduct(productBarcode, placeBarcode, value);
      console.log(res.data)
      handleClearProduct()
      addNotification({ status: 222, message: "Success Replenishment", color: ToastColor.Primary });
    } catch (error) {
      addNotification({ status: 222, message: "There was a problem replenishing the product", color: ToastColor.Secondary });
    }
  };

  useEffect(() => {
  }, []);

  return (
    < Card >
      <Grid>
        <Row>
          <Column>
            <Typography content='Replenishment' type={HeadingType.H1} />
          </Column>
        </Row>
        <>
          {selectedProduct.sku ? (
            <>
              <Typography content='Selected Product' type={HeadingType.H3} />
              <p>Name: {selectedProduct.name}</p>
              <p>SKU: {selectedProduct.sku}</p>
              <p>ID: {selectedProduct.organization_id}</p>
              <Fieldset>
                <Label>Place barcode</Label>
                <Input
                  title='Place Barcode'
                  type={'text'}
                  name={'place_barcode'}
                  onChange={(e: any) => setPlaceBarcode(e.target.value)}
                  value={placeBarcode}
                  autoFocus={true}
                />
              </Fieldset>
              <Label>Quantity to Replenish</Label>
              <Row>
                <Column>
                  <Fieldset>
                    <Input
                      title='Replenish Quantity'
                      type={'number'}
                      name={'pick_qty'}
                      onChange={(e: any) => setValue(e.target.value)}
                      value={value}
                    />
                  </Fieldset>
                  <ButtonGroup>
                    <Button onClick={handleMinus} type="submit">-</Button>
                    <Button onClick={handlePlus} type="submit">+</Button>
                  </ButtonGroup>
                </Column>
              </Row>
              <br />
              <Row>
                <Column>
                  <Button onClick={handleClearProduct}>Clear</Button>
                  <Button onClick={handleReplenish}>Submit Replenishment</Button>
                </Column>
              </Row>
            </>
          ) : (
            <form>
              <Fieldset>
                <Label>Scan Product Barcode</Label>
                <Input
                  title='Product Barcode'
                  type={'text'}
                  name={'product_barcode'}
                  onChange={(e: any) => setProductBarcode(e.target.value)}
                  value={productBarcode}
                  autoFocus={true}
                />
                <br />
                <div className='row'>
                  <div className='column'>
                    <Button disabled={allowButtonSubmit()} onClick={handleFindProduct} type="submit">Pick Product</Button>
                  </div>
                </div>
              </Fieldset>
            </form>
          )}
        </>
      </Grid>
    </Card >
  );
};

export default Replenishment;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles, UrsaProvider } from 'ursa-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const customTheme = {
  // Define other theme properties

};

root.render(
  <UrsaProvider customTheme={customTheme}>
    <GlobalStyles />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </UrsaProvider>
);


import React, { useState, useEffect } from 'react';
import Facility from '../components/Facility';
import Organization from '../components/Organization';
import Place from '../components/Place';
import Product from '../components/Product';
import Zone from '../components/Zone';
import { Button, ButtonGroup, Card, Typography } from 'ursa-react';

const Home = () => {
  const [step, setStep] = useState(0);
  const [showWelcome] = useState(true);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);


  useEffect(() => {
  }, []);

  return (
    <Card>
      {showWelcome ? (
        <div className='column'>
          <div className='row'>
            <ButtonGroup>
              <Button onClick={onPrevious} disabled={step === 0}>
                Previous
              </Button>
              <Button onClick={onNext} disabled={step === 4}>
                Next
              </Button>
            </ButtonGroup>
          </div>
          <br />
          <div className='row'>
            <div className='column'>
              {step === 0 && (
                <>
                  <Typography type='h2' content='Organization' />
                  <Organization />
                </>
              )}
              {step === 1 && (
                <>
                  <Typography type='h2' content='Facilities' />
                  <Facility />
                </>
              )}
              {step === 2 && (
                <>
                  <Typography type='h2' content='Zones' />
                  <Zone />
                </>
              )}
              {step === 3 && (
                <>
                  <Typography type='h2' content='Places' />
                  <Place />
                </>
              )}
              {step === 4 && (
                <>
                  <Typography type='h2' content='Products' />
                  <Product />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>
          <h1>Dashboard</h1>
        </div>
      )}
    </Card>
  );
};

export default Home;

import React, { useState } from 'react';
import NavLinks from "./NavLinks";

function MobileNav() {
    return (
        <div className='mobile-nav'>
            <NavLinks />
        </div>
    );
}

export default MobileNav;
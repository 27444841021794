/* eslint-disable require-jsdoc */
import useLocalStorage from '@rehooks/local-storage';
import React, { useEffect, useState } from 'react';
import { Button, Card, Fieldset, Input, Label, Typography } from 'ursa-react';
import AccountNav from '../components/AccountNav';
import FacilitySelector from '../components/FacilitySelector';

const Account = () => {
  const user = useLocalStorage('user');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    setFirstName(user[0].user.first);
    setLastName(user[0].user.last);
    setPhone(user[0].user.phone);
    setEmail(user[0].user.email);
  }, []);

  return (
    <Card>
      <div className='column'>
        <AccountNav />
        <div className='row'>
          <Typography type='h3' content='Account' />
        </div>
        <div>
          <Fieldset>
            <Label>First Name</Label>
            <Input
              disabled={true}
              type={'text'}
              name={'first_name'}
              value={firstName}
            />
            <Label>Last Name</Label>
            <Input
              disabled={true}
              type={'text'}
              name='last_name'
              value={lastName}
            />
            <Label>Phone</Label>
            <Input
              disabled={true}
              name='phone'
              type={'tel'}
              value={phone}
            />
            <Label>Email</Label>
            <Input
              disabled={true}
              type={'email'}
              name='email'
              value={email}
            />
            <FacilitySelector />

            <br />
            <Button disabled={true}>
              Update
            </Button>
          </Fieldset>
        </div>
      </div>
    </Card>
  );
};

export default Account;

import React, { useState, useEffect } from 'react';
import { Column, Dropdown, Grid, Label, List, Row, Typography } from 'ursa-react';
import { VisoClient } from '../utils/visoClient';
import { HeadingType } from 'ursa-react/dist/components/Typography/Typography';

const FacilitySelector = () => {
  const [facilityList, setFacilityList] = useState([])

  const handleClickListItem = async (e: any) => {
    console.log(e.target.innerText)
    const res = await VisoClient().user.setActiveFacility(e.target.innerText)
    console.log(res);
  }

  const handleSelectFacility = async () => {
    try {
      const res = await VisoClient().user.setActiveFacility("")
      console.log(res);
      handleRefreshFacilities();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshFacilities = async () => {
    const resul = await VisoClient().facility.getFacilities();
    const list = [] as any
    for (let zone of resul.data.results) {
      list.push({label: zone.name, value: zone.id})
    }
    setFacilityList(list);
  };

  useEffect(() => {
    handleRefreshFacilities();
  }, []);

  return (
    <>
        <Label>Active Facility</Label>
        <Dropdown inputTitle='selectFacility' options={facilityList} />
    </>
  );
};


export default FacilitySelector;



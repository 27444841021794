import React, { useState, useEffect } from 'react';
import { Table } from 'rsuite';
import { Button, Fieldset, Input, Label } from 'ursa-react';
import { VisoClient } from '../utils/visoClient';

// eslint-disable-next-line react/prop-types
const ProductSearch = ({handleClick, fluid}) => {
  const [productList, setProductList] = useState([]);
  const [searchSku, setSearchSku] = useState("");
  const [loading, setLoading] = useState(true);

  const handleRefreshProducts = async () => {
    try {
      if(searchSku != ""){
        const resul = await VisoClient().product.getProducts(searchSku)
        console.log(resul.data)
        setProductList(resul.data.results);
      }else{
        const resul = await VisoClient().product.getProducts()
        console.log(resul.data)
        setProductList(resul.data.results);
      }

      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }

    setLoading(false)
  };

  useEffect(() => {
    console.log(fluid);
    setLoading(true)
    handleRefreshProducts();
  }, [fluid]);

  return (
    <>
      <div className='row'>
        <div className='column'>
        <h4>Products List</h4>
        <Fieldset>
            <Label>Search Sku</Label>
            <Input
              type={'text'}
              name={'search_sku'}
              onChange={(e) => setSearchSku(e.target.value)}
              value={searchSku}
            />
            <Button
              onClick={handleRefreshProducts}
              disabled={false}>Filter Products</Button>
          </Fieldset>
        <Table
            height={400} 
            loading={loading}
            rowKey="id"
            data={productList}
            onRowClick={(rowData) => {
              // eslint-disable-next-line react/prop-types
              handleClick(rowData);
            }}>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Product Sku</Table.HeaderCell>
              <Table.Cell dataKey="sku" />
            </Table.Column>

            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Product Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
          </Table>
        </div>
      </div>
    </>
  );
};


export default ProductSearch;



import React, {useState, useEffect} from 'react';
import { SelectPicker } from 'rsuite';
import {Button, Fieldset, Input, Label, List} from 'ursa-react';
import {VisoClient} from '../utils/visoClient';

const Zone = () => {
  const [zoneName, setZoneName] = useState('');
  const [zoneList, setZoneList] = useState([]);
  const [zoneFacility, setZoneFacility] = useState(null);

  const handleCreateZone = async () => {
    try {
      await VisoClient().zone.createZone(zoneName);
      handleRefreshZoneList(zoneFacility);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshZoneList = async () => {
    try {
      const res = await VisoClient().zone.getZones();
      const list = []
      for(let zone of res.data.results){
        list.push(zone.name)
      }
      setZoneList(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleRefreshZoneList("")
  }, []);

  return (
    <>
      {zoneList !== null && (
        <div className='row'>
          <div className='flex-half'>
            <h4>Setup Zones</h4>
            <Fieldset>
              <Label>Zone Name</Label>
              <Input
                type={'text'}
                name={'zone_name'}
                onChange={(e) => setZoneName(e.target.value)}
                value={zoneName}></Input>
              <Button
                onClick={handleCreateZone}
                disabled={false}>Create a Zone</Button>
            </Fieldset>
          </div>
          <div className='flex-half'>
            <h4>Your Current Zones:</h4>
            <List items={zoneList}></List>
          </div>
        </div>
      )}

    </>
  );
};


export default Zone;



/* eslint-disable require-jsdoc */
import React from 'react';
import { Typography } from 'ursa-react/dist/components';

function Error404() {
  return (
    <Typography type='h2' content='404 Oh no that page does not exist.' />
  );
}

export default Error404;

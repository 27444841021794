/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Sidenav} from 'rsuite';
import NavLinks from './NavLinks';

const LeftNav = () => {
  const [expanded] = useState(true);

  return (
    <div>
      <Sidenav className='side-nav' expanded={expanded}>
        <Sidenav.Body>
          <NavLinks />
          {/* <List onClick={handleNavClick} items={['Logout', 'Dashboard', 'Inventory', 'Picking', 'Replenishment']}></List> */}
        </Sidenav.Body>
        {/* <Sidenav.Toggle onToggle={setExpanded} /> */}
      </Sidenav>
    </div>
  );
};

export default LeftNav;

import React, { useState, useEffect } from 'react';
import { Button, Fieldset, Input, Label } from 'ursa-react';
import { VisoClient } from '../utils/visoClient';
import ProductSearch from './ProductSearch';

const Product = () => {
  const [productSku, setProductSku] = useState("");
  const [productName, setProductName] = useState("");
  const [renderSeach, setRenderSearch] = useState("");

  const handleCreateProduct = async () => {
    try {
      console.log(productName, productSku)
      const resul = await VisoClient().product.createProduct(productSku, productName)
      
      setRenderSearch(resul.data.sku)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <div className='row'>
        <div className='flex-half'>
          <h4>Add a Product</h4>
          <Fieldset>
            <Label>Product Name</Label>
            <Input
              type={'text'}
              name={'product_name'}
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
            />
            <Label>Product Sku</Label>
            <Input
              type={'text'}
              name={'product_sku'}
              onChange={(e) => setProductSku(e.target.value)}
              value={productSku}
            />
            <Button
              onClick={handleCreateProduct}
              disabled={false}>Create Product</Button>
          </Fieldset>
          </div>
          <div className='flex-half'>
          <ProductSearch fluid={renderSeach} />
          </div>
      </div>
    </>
  );
};


export default Product;



/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from 'ursa-react';

const AccountNav = () => {
    const [active, setActive] = useState('');
    const navigate = useNavigate();

    const handleSwitchRadio = (event) => {
        console.log(event.target.id)
        navigate(event.target.id);

    }

    return (
        <ButtonGroup>
            <Button onClick={handleSwitchRadio} id="/account" >Account Details</Button>
            <Button onClick={handleSwitchRadio} id="/manage-org" >Manage Organization</Button>
        </ButtonGroup>
    );
};

export default AccountNav;

import React, {useState, useEffect} from 'react';
import {SelectPicker} from 'rsuite';
import {Button, Fieldset, Input, Label, List} from 'ursa-react';
import {VisoClient} from '../utils/visoClient';

const Place = () => {
  const [zoneList, setZoneList] = useState([]);

  const [placeZone, setPlaceZone] = useState(null);

  const [placeList, setPlaceList] = useState([]);
  const [barcode, setPlaceBarcode] = useState('');


  const handleCreatePlace = async () => {
    try {
      await VisoClient().place.createPlace(barcode, placeZone);
      handleRefreshPlaceList(placeZone);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefreshPlaceList = async (zoneId) => {
    try {
      const res = await VisoClient().place.getZonePlaces(zoneId);
      const list = []
      for(let place of res.data.results){
        list.push(place.barcode)
      }
      setPlaceList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleZoneChange = async (e) => {
    setPlaceBarcode('');
    console.log(e)
    setPlaceZone(e);
    handleRefreshPlaceList(e);
  };


  const handleRefreshZoneList = async (facilityId) => {
    const resul = await VisoClient().zone.getZones(facilityId);
    console.log(resul.data.results)
    const data = resul.data.results.map(
        (item) => ({label: item.name, value: item.name}),
    );
    console.log(data)

    setZoneList(data);
    console.log(data)
  };

  useEffect(() => {
    handleRefreshZoneList();
  }, []);

  return (
    <>
      <div className='row'>
        <div className='column'>
          <h4>Choose a Zone</h4>
          <SelectPicker
            label="Zone"
            data={zoneList}
            onChange={(e) => handleZoneChange(e)} />
        </div>
      </div>
      {placeZone !== null && (
        <div className='row'>
          <div className='flex-half'>
            <h4>Setup Place</h4>
            <Fieldset>
              <Label>Place Name</Label>
              <Input
                type={'text'}
                name={'place_name'}
                onChange={(e) => setPlaceBarcode(e.target.value)}
                value={barcode}></Input>
              <Button
                onClick={handleCreatePlace}
                disabled={false}>Create a Place</Button>
            </Fieldset>
          </div>
          <div className='flex-half'>
            <h4>Your Current Places:</h4>
            <List items={placeList} />
          </div>
        </div>
      )}
    </>
  );
};


export default Place;



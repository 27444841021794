import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'ursa-react';
import VisoLogo from './VisoLogo';

const LogoBar = () => {
  return (
    <div className='logo-bar'>
        <VisoLogo/>
    </div>
  );
};

export default LogoBar;
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Column, Fieldset, Grid, Input, Label, Row, Typography } from 'ursa-react';
import { VisoClient } from '../utils/visoClient';
import { HeadingType } from 'ursa-react/dist/components/Typography/Typography';
import { useNotification } from '../context/NotificationProvider';
import { ToastColor } from 'ursa-react/dist/components/Toast/Toast';

const Picking = () => {
  const { addNotification } = useNotification();
  const [pickQty, setPickQty] = useState(1);
  const [placeId, setPlaceId] = useState("");
  const [showProductPick, setShowProductPick] = useState(false);
  const [productData, setProductData] = useState({ product: { name: "", sku: "" }, quantity: 0 })


  const handleMinus = () => {
    if (pickQty >= 2) {
      setPickQty(pickQty - 1);
    }
  };

  const handlePlus = () => {
    setPickQty(pickQty + 1);
  };

  const handleClearPicking = () => {
    setProductData({ product: { name: "", sku: "" }, quantity: 0 })
    setShowProductPick(false)
    setPlaceId("")
    setPickQty(1)
  }

  const handlePickPlace = async (event: any) => {
    event.preventDefault();
    try {
      const res = await VisoClient().product.getProductAtPlace(placeId)
      console.log(res)
      setProductData(res.data)
      setShowProductPick(true);
      addNotification({ message: `Found Place`, color: ToastColor.Primary, status: 2 })
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error Finding Place`, color: ToastColor.Secondary, status: 2 })
    }
  };

  const handlePickProduct = async () => {
    try {
      const respon = await VisoClient().product.pickProduct(placeId, pickQty)
      console.log(respon)
      addNotification({ message: `Submit for With qty ${pickQty} with place ${placeId}`, color: ToastColor.Primary, status: 2 })      
      handleClearPicking()
    } catch (error) {
      console.log(error)
      addNotification({ message: `Error Picking Product`, color: ToastColor.Secondary, status: 2 })
    }
  }

  const allowButtonSubmit = () => {
    if (placeId !== "") {
      return false;
    }
    return true;
  }

  useEffect(() => {
  }, []);

  return (
    <Card>
      <Grid>
        <Row>
          <Column>
            <Typography content='Picking' type={HeadingType.H1} />
          </Column>
        </Row>
        {showProductPick ? (
          <>
            <div>
              <br>
              </br>
              <Typography type={HeadingType.H3} content={"Product Name: " + productData.product.name} />
              <Typography type={HeadingType.Paragraph} content={"Product Sku: " + productData.product.sku} />
              <Typography type={HeadingType.Paragraph} content={"Product Qty: " + productData.quantity} />
              <br>
              </br>
              <br>
              </br>
              <Typography type={HeadingType.H3} content='How many do you want'></Typography>
              <Row>
                <Column>
                  <Label>Quantity to Pick</Label>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Fieldset>
                    <Input
                      title='Pick Quantity'
                      type={'number'}
                      name={'pick_qty'}
                      onChange={(e: any) => setPickQty(e.target.value)}
                      value={pickQty}
                    />
                  </Fieldset>

                  <ButtonGroup>
                    <Button onClick={handleMinus} type="submit">-</Button>
                    <Button onClick={handlePlus} type="submit">+</Button>
                  </ButtonGroup>
                </Column>
              </Row>

              <br />
              <div className='row'>
                <div className='column'>
                  <Button disabled={allowButtonSubmit()} onClick={handlePickProduct} type="submit">Pick Product</Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Row>
            <Column>
              <form>
                <Fieldset>
                  <Label>Scan Place Barcode</Label>
                  <Input
                    title="Place Barcode"
                    type={'text'}
                    name={'place_id'}
                    onChange={(e: any) => setPlaceId(e.target.value)}
                    value={placeId}
                    autoFocus={true}
                  />
                  <br />
                  <div className='row'>
                    <div className='column'>
                      <Button disabled={allowButtonSubmit()} onClick={handlePickPlace} type="submit">Pick Place</Button>
                    </div>
                  </div>
                </Fieldset>
              </form>
            </Column>
          </Row>
        )}
      </Grid>
    </Card>
  );
};

export default Picking;

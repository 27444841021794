import { Toast } from 'ursa-react';
import { useNotification } from '../context/NotificationProvider';
import React from 'react';

function Notification() {
    const { notification, removeNotification } = useNotification();

    const handleToastClose = () => {
        removeNotification();
    };

    return (
        <>
            {notification && (
                <Toast visible={true} message={notification.message} onClose={handleToastClose} color={notification.color} />
            )}
        </>
    )
}

export { Notification }